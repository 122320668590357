<script setup lang="ts">
import consola from 'consola'
import { NewsIdMap, NewsType, SpecialType, SpecialTypeMap } from '~/constants'
import { emitter } from '~/utils/mitt'

type _LatestPriceFiled = 'mprice' | 'vprice' | 'oprice' | 'b3price'

enum LocaleMap {
  EN = 'en',
  TW = 'tw',
}

enum DiscountTypeEnum {
  '網店',
  '門市',
  '網店/門市',
}
enum DiscountTypeEnumEn {
  'Online store',
  'Store',
  'Online store/Store',
}

const latestPriceFiled = [
  'mprice',
  'vprice',
  'oprice',
  'b3price',
]

const { categoriesComputed, otherCategories, getCategories, holidayStore } = useCategoryStore()
const { t, locale } = useI18n()
const localPath = useLocalePath()
const authStore = useAuthStore()
const user = useState<_JwtPayload | null>('user', () => null)

const userinfo = computed(() => {
  try {
    return user.value
  }
  catch (error) {
    consola.error(error)
    return authStore.userinfo
  }
})

const priceFiled = computed<_LatestPriceFiled>(() => {
  let filed = 'oprice'
  if (userinfo.value && userinfo.value.mbType) {
    filed = `${userinfo.value.mbType.toLocaleLowerCase()}price`
    if (!latestPriceFiled.includes(filed)) {
      filed = 'oprice'
    }
  }
  if (filed === 'b3price') {
    return 'b3Price' as _LatestPriceFiled
  }
  return filed as _LatestPriceFiled
})

const route = useRoute()
const { addCart } = useAddCart()

const activeCategory = useState<number | string>('ActiveCategory')
const Lang = ref<LocaleMap>(LocaleMap.TW)

if ((route.name as string)!.includes('___en')) {
  Lang.value = LocaleMap.EN
}

// 分类
function onClickTab({ name }: { name: number | string }) {
  if (isInsideIframe)
    return
  if (name === 'all') {
    categoryDetail()
  }
  activeCategory.value = name

  if (name === 'gala' && Array.isArray(holidayStore.value)) {
    consola.info(holidayStore.value)
    const firstHoliday = (holidayStore.value[0] as any)?.code
    if (firstHoliday) {
      navigateTo(
        localPath({
          path: `/category/${name}/${firstHoliday}`,
        }),
      )
    }
    return
  }
  const sub = getCategories.value.find(item => item.code === name)
  if (sub && sub.children && Array.isArray(sub.children)) {
    navigateTo(
      localPath({
        path: `/category/${name}/${sub.children[0].code}`,
      }),
    )
  }
  else {
    navigateTo(
      localPath({
        path: `/category/${name}`,
      }),
    )
  }
};
function categoryDetail() {
  if (isInsideIframe)
    return
  navigateTo(localPath('/category/all'))
}

/** 特价商品 ｜ 热卖商品 */
const bodyActive = useState<SpecialType>('_bodyActive', () => SpecialType.SPECIAL_PRODUCT)
// 显示对应的商品
const _activeProduct = useState<_LatestProduct[]>('_activeProduct', () => [])
const activeProduct = useState<_LatestProduct[]>('activeProduct', () => [])
// 热卖商品
const hotProducts = useState<_LatestProduct[]>(SpecialType.HOT_PRODUCT, () => [])

await useLazyFetch<_ResponseType<_Pagination<_LatestProduct[]>>>('/api/activityProduct/list', {
  method: 'POST',
  watch: [bodyActive],
  params: {
    size: 999999,
    current: 1,
    type: SpecialTypeMap[bodyActive.value],
  },
  onRequest({ options }) {
    options.params!.type = SpecialTypeMap[bodyActive.value]
    if (bodyActive.value === SpecialType.SPECIAL_PRODUCT) {
      options.params!.isPublish = 1
    }
  },
  onResponse({ response }) {
    const { code, data: products } = response._data
    if (code && code === 200) {
      _activeProduct.value = products.records
      // 只显示前10个
      activeProduct.value = products.records.slice(0, 10)
      if (bodyActive.value === SpecialType.HOT_PRODUCT) {
        hotProducts.value = products.records
      }
    }
  },
})

/** 首页轮播图 */
const banners = useState<_BannerList[]>('_BannerList', () => [])
/** 底部图片 */
const botttomImg = useState<Partial<_BannerList>>('_bottomImg', () => ({}))

/** 最新商品 */
const latestProductList = useState<_LatestProduct[]>('_latestProductList', () => [])

/** 当前显示类型 */
const activeNews = useState<NewsType>('_activeNews', () => NewsType.OFFICIAL_NEWS)

/** 给最新商品的价格做的转换 */
function formatPriceFiled(product: _HashMapListItem): any {
  const listFileds: Record<string, string> = {
    oprice: 'aprice', // 網店會員
    mprice: 'bprice', // 基本會員
    b3Price: 'eprice', // 批發會員
    vprice: 'bprice', // 永久會員
    inprice: 'aprice', // 機構會員
    reprice: 'eprice', // 代理會員
  }
  const productNew: Record<keyof typeof listFileds, any> = {}
  Object.keys(listFileds).forEach((key: keyof typeof listFileds) => {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    productNew[key] = product[listFileds[key]] ? Number(product[listFileds[key]]) : undefined
  })
  return productNew
}

const { data, refresh, status } = await useLazyFetch<_ResponseType<_HomeData>>('/api/homeData')

/** 根据显示类型显示的数据 */
const newProducts = computed<_IchiShareList[]>(() => {
  const typeId = NewsIdMap[activeNews.value]

  if (!typeId) {
    return []
  }

  if (data.value && data.value.data && data.value.data.ichiShareList && Array.isArray(data.value.data.ichiShareList)) {
    return data.value.data.ichiShareList.filter(item => item.typeId === typeId)
  }
  return []
})
/** 优惠资讯 */
const discountNews = computed<_IchiShareList[]>(() => {
  return data.value && data.value.data && data.value.data.ichiShareList && Array.isArray(data.value.data.ichiShareList) ? data.value.data.ichiShareList.filter(item => item.typeId === NewsIdMap[NewsType.DISCOUNT_NEWS]) : []
})

watch(() => status.value, (newVal) => {
  if (newVal === 'success') {
    if (data.value && data.value.data) {
      banners.value = data.value.data?.bannerList || []
      botttomImg.value = Array.isArray(data.value.data.bottomPicListTwo) ? data.value.data.bottomPicListTwo[0] : {}
      if (data.value.data.latestProductList) {
        latestProductList.value = data.value.data.latestProductList?.map(item => ({ ...item, b3price: item.b3Price })) || []
      }
      else {
        latestProductList.value = data.value.data.hashMapList.map((item) => {
          return {
            ...item,
            new: true,
            name: locale.value === 'en' ? item.descript : item.cdescript,
            code: item.scode,
            picture: item.path1,
            ...formatPriceFiled(item),
          }
        }) as unknown as _LatestProduct[]
      }
    }
  }
}, {
  immediate: true,
  deep: true,
  flush: 'post',
})

function productDetail(code: string) {
  if (isInsideIframe)
    return
  navigateTo(localPath(`/product/${code}`))
}

function onClickSwipe(event: MouseEvent, image: _BannerList) {
  if (isInsideIframe) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()

    window.parent.postMessage({
      type: 'page-define',
      id: image.id,
      imgTypeId: 21,
    }, '*')
  }
}

function handleJoinCart(product: any) {
  addCart({
    count: 1,
    cat: product.itemType || product.cat,
    scode: product.code || product.scode,
    img: product.picture || product.path1,
    price: Number(product[priceFiled.value]),
    name: product.name || product.cdescript,
  })
}

onMounted(() => {
  emitter.on('bannerChange', (_data: _BannerList[]) => {
    refresh()
    // banners.value = data
  })
})

const moreContent = ref()

const { data: seoData, status: seoStatus } = await useFetch<_Seo[]>('/api/seoRelation/list', {
  method: 'POST',
  body: {
    paths: [useRoute().path],
  },
})

watch(() => seoStatus.value, (newVal) => {
  if (newVal === 'success') {
    if (seoData.value && Array.isArray(seoData.value) && seoData.value.length) {
      const { content } = JSON.parse(JSON.stringify(seoData.value)).pop()
      moreContent.value = content.content
      const { title, meta } = content
      useHead({
        title,
        meta,
      })
    }
  }
}, {
  immediate: true,
})

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://m.ichi-cake.com/',
    },
    {
      rel: 'alternate',
      media: 'only screen and (max-width: 640px)',
      href: 'https://m.ichi-cake.com/',
    },
  ],
})
</script>

<template>
  <div>
    <div class="main">
      <with-header with-search :fixed="false" :placeholder="false" />
      <!-- <app-header /> -->
      <van-tabs
        v-model:active="activeCategory"
        :line-height="0"
        @click-tab="onClickTab"
      >
        <template #nav-left>
          <div class="fixedly-btn-right" @click="categoryDetail">
            <van-icon name="wap-nav" size="26" />
          </div>
        </template>
        <van-tab
          v-for="item in categoriesComputed"
          :key="item.id"
          :name="item.code"
          :title="locale === LocaleMap.EN ? item.nameEn : item.name"
        />
        <van-tab
          name="gala"
          :title="locale === LocaleMap.EN ? 'Gala' : '節日'"
        />
        <van-tab
          v-for="item in otherCategories"
          :key="item.id"
          :name="item.code"
          :title="locale === LocaleMap.EN ? item.nameEn : item.name"
        />
        <!-- <template #nav-left>
          <van-icon
            name="wap-nav"
            class="fixedly-btn-right"
            @click="categoryDetail"
          >
            {{ t("Classification") }}
          </van-icon>
        </template> -->
      </van-tabs>

      <!-- 轮播图 -->
      <van-swipe
        :autoplay="5000"
        class="custom-swipe"
      >
        <van-swipe-item
          v-for="image in banners"
          :key="image.id"
          class="custom-swipe-item"
          @click="event => onClickSwipe(event, image)"
        >
          <nuxt-link :external="isInsideIframe" :to="image.linkUrl" :target="image.openLink === 1 ? '_blank' : '_self'">
            <img
              :src="gradualImg(image.url)"
              class="van-swipe-item__image"
              :alt="image.name"
            >
          </nuxt-link>
        </van-swipe-item>
      </van-swipe>
      <!-- 最新商品 -->
      <div class="Goods">
        <div
          class="title"

          flex items-center justify-between
        >
          <div class="title-left">
            <svgo-new
              filled
              class="mr-2 text-3xl"
            />
            <div>{{ t("Latest Products") }}</div>
          </div>
        </div>
        <div class="flex flex-grow-1 flex-row gap-x-2 overflow-x-auto">
          <template v-for="(item, index) in latestProductList" :key="index">
            <div class="min-w-160px w-full p-1.25">
              <img class="w-full rounded-10px" :src="gradualImg(item.picture)" onerror="this.src='/images/default/detailt-product.jpeg'" :alt="item.name" @click="productDetail(item.code)">
              <div
                mt-1
                font-size-14px
              >
                {{ item.name }}
              </div>
              <div
                flex
                justify-between
                class="price"
              >
                <div
                  flex
                  items-baseline
                >
                  <div font-size-14px>
                    HK$
                  </div>
                  <div class="font-size-16px font-bold">
                    {{ item[priceFiled]?.toFixed(2) }}
                  </div>
                </div>
                <svgo-shopcar
                  filled
                  class="text-xl"
                  @click="handleJoinCart(item)"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 特价商品 -->
      <div class="Goods">
        <div
          class="title"

          flex items-center justify-between
        >
          <div class="title-left">
            <svgo-specialOffer
              filled
              class="mr-1 text-2xl"
            />
            <div
              :class="bodyActive === SpecialType.SPECIAL_PRODUCT ? 'highlight' : ''"
              @click="bodyActive = SpecialType.SPECIAL_PRODUCT"
            >
              {{ t("special offer") }}
            </div>
          </div>
          <div class="title-left">
            <svgo-coupons
              filled
              class="mr-1 text-2xl"
            />
            <div
              :class="bodyActive === SpecialType.HOT_PRODUCT ? 'highlight' : ''"
              @click="bodyActive = SpecialType.HOT_PRODUCT"
            >
              {{ t("Hot items") }}
            </div>
          </div>
          <div class="title-right" @click="isInsideIframe ? void 0 : navigateTo(localPath(`/category/${bodyActive === SpecialType.HOT_PRODUCT ? 'F20' : 'F21'}`))">
            <div>{{ t("View All") }}</div>
            <svgo-jiantou
              filled
              class="text-xl"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 mt-5 gap-x-4 gap-y-6">
          <div
            v-for="item in activeProduct"
            :key="item.id"
            class="product-item"
          >
            <img
              class="aspect-ratio-square rounded-2xl"
              :src="gradualImg(item.picture)"
              :alt="item.name"
              @click="productDetail(item.code)"
            >
            <!-- 显示优惠信息 -->
            <div v-if="bodyActive === SpecialType.SPECIAL_PRODUCT" class="overflow-hidden text-ellipsis whitespace-nowrap bg-theme px-1 py-0.5 text-sm text-white">
              <template v-if="locale === LocaleMap.EN">
                {{ `${DiscountTypeEnumEn[item.discountType!]}: ${item.storeDiscountEn}` }}
              </template>
              <template v-else>
                {{ `${DiscountTypeEnum[item.discountType!]}: ${item.storeDiscount}` }}
              </template>
            </div>
            <div
              mt-2.5
              font-size-14px
            >
              {{ item.name }}
            </div>
            <div class="price flex items-end justify-between">
              <div
                flex
                items-baseline
              >
                <div font-size-13px>
                  HK$
                </div>
                <div class="font-size-16px font-500">
                  {{ item[priceFiled]?.toFixed(2) }}
                </div>
              </div>
              <svgo-shopcar
                filled
                class="text-xl"
                @click="handleJoinCart(item)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 最新资讯 -->
      <div class="Goods">
        <!-- banner -->
        <div

          mb-4 flex justify-between
        >
          <template v-if="data?.data?.middlePicListOne?.length">
            <NuxtLink v-for="item in data?.data?.middlePicListOne" :key="item.id" class="w-[48%]" :external="isInsideIframe" :to="item.linkUrl" :target="item.openLink === 1 ? '_blank' : '_self'">
              <img
                class="h-[60px] w-full rounded-[5px] object-cover"
                :src="gradualImg(item.url)"
                :alt="item.name"
                data-event-type="page-define"
                :data-event-id="item.id"
              >
            </NuxtLink>
            <template v-if="data?.data?.middlePicListOne?.length === 1">
              <NuxtImg
                class="h-[60px] w-[48%] rounded-[5px] object-cover"
                src="/images/default/detailt-product.jpeg"
                alt="default pic"
                data-event-type="page-define"
                :data-type-id="75"
              />
            </template>
          </template>
          <template v-else>
            <NuxtImg
              class="h-[60px] w-[48%] rounded-[5px] object-cover"
              src="/images/default/detailt-product.jpeg"
              alt="default pic"
              data-event-type="page-define"
              :data-type-id="75"
            />
            <NuxtImg
              class="h-[60px] w-[48%] rounded-[5px] object-cover"
              src="/images/default/detailt-product.jpeg"
              alt="default pic"
              data-event-type="page-define"
              :data-type-id="75"
            />
          </template>
        </div>
        <div class="mb-4">
          <template v-if="data?.data?.middlePicListTwo?.length">
            <NuxtLink v-for="item in data?.data?.middlePicListTwo" :key="item.id" :external="isInsideIframe" :to="item.linkUrl" :target="item.openLink === 1 ? '_blank' : '_self'">
              <img
                class="h-[120px] w-full rounded-[5px] object-cover"
                :src="gradualImg(item.url)"
                :alt="item.name"
                data-event-type="page-define"
                :data-event-id="item.id"
              >
            </NuxtLink>
          </template>
          <template v-else>
            <NuxtImg
              class="h-[120px] w-full rounded-[5px] object-cover"
              src="/images/default/detailt-product.jpeg"
              alt="default pic"
              data-event-type="page-define"
              :data-type-id="76"
            />
          </template>
        </div>
        <div class="title">
          <div class="title-left">
            <svgo-newInfo
              filled
              class="mr-2 text-2xl"
            />
            <div>{{ t("Latest news") }}</div>
          </div>
        </div>
        <div class="title-level2">
          <div
            flex
            items-end
          >
            <div
              class="title-left"
              :class="activeNews === NewsType.OFFICIAL_NEWS ? 'highbackground' : ''"
              @click="activeNews = NewsType.OFFICIAL_NEWS"
            >
              <svgo-LatestNews
                v-if="activeNews === NewsType.OFFICIAL_NEWS"
                filled
                class="mr-1 text-2xl"
              />
              <svgo-latestNews2
                v-else
                filled
                class="mr-1 text-2xl"
              />
              <div>{{ t("official_news") }}</div>
            </div>
            <div
              class="title-left"
              :class="activeNews === NewsType.BAKING_NEWS ? 'highbackground' : ''"
              @click="activeNews = NewsType.BAKING_NEWS"
            >
              <svgo-baking
                v-if="activeNews === NewsType.BAKING_NEWS"
                filled
                class="mr-1 text-2xl"
              />
              <svgo-baking2
                v-else
                filled
                class="mr-1 text-2xl"
              />
              <div>{{ t("baking_news") }}</div>
            </div>
          </div>
          <div class="title-right" @click="isInsideIframe ? void 0 : navigateTo(localPath(`/information/${activeNews}`))">
            <div>{{ t("View All") }}</div>
            <svgo-jiantou
              filled
              class="text-xl"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 mt-5 gap-x-4 gap-y-6">
          <div
            v-for="item in newProducts"
            :key="item.id"
          >
            <img
              class="aspect-ratio-square rounded-xl object-contain"
              :src="gradualImg(item.imgUrl)"
              :alt="item.title"
              @click="isInsideIframe ? void 0 : navigateTo(localPath(`/information/detail/${item.id}`))"
            >
            <div class="mt-2.5 text-sm text-#121212">
              {{ item.title }}
            </div>
            <div class="content-describe" />
          </div>
        </div>
      </div>
      <!-- 優惠資訊 -->
      <div class="Goods">
        <div class="title">
          <div class="title-left">
            <svgo-discountinfo
              filled
              class="mr-2 text-2xl"
            />
            <div>{{ t("Discount information") }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 mt-5 gap-x-4 gap-y-6">
          <div
            v-for="item in discountNews"
            :key="item.id"
            class="item"
          >
            <img class="aspect-ratio-square rounded-xl object-contain" :src="gradualImg(item.imgUrl)" :alt="item.title">
            <div class="mt-2.5 text-sm text-#121212">
              {{ item.title }}
            </div>
            <div class="content-describe" />
          </div>
        </div>
      </div>
      <div class="Goods">
        <template v-if="data?.data?.middlePicListThree?.length">
          <NuxtLink v-for="item in data?.data?.middlePicListThree" :key="item.id" :external="isInsideIframe" :to="item.linkUrl" :target="item.openLink === 1 ? '_blank' : '_self'">
            <img
              class="mb-3 h-[114px] w-full rounded-[5px] object-cover"
              :src="gradualImg(item.url)"
              :alt="item.name"
              data-event-type="page-define"
              :data-event-id="item.id"
            >
          </NuxtLink>
        </template>
        <template v-else>
          <NuxtImg
            class="mb-3 h-[114px] w-full rounded-[5px] object-cover"
            src="/images/default/detailt-product.jpeg"
            alt="default pic"
            data-event-type="page-define"
            :data-type-id="77"
          />
        </template>

        <template v-if="data?.data?.middlePicListFour?.length">
          <NuxtLink v-for="item in data?.data?.middlePicListFour" :key="item.id" :external="isInsideIframe" :to="item.linkUrl" :target="item.openLink === 1 ? '_blank' : '_self'">
            <img
              class="mb-3 h-[114px] w-full rounded-[5px] object-cover"
              :src="gradualImg(item.url)"
              :alt="item.name"
              data-event-type="page-define"
              :data-event-id="item.id"
            >
          </NuxtLink>
        </template>
        <template v-else>
          <NuxtImg
            class="mb-3 h-[114px] w-full rounded-[5px] object-cover"
            src="/images/default/detailt-product.jpeg"
            alt="default pic"
            data-event-type="page-define"
            :data-type-id="78"
          />
        </template>
      </div>
      <!-- 品牌專櫃 -->
      <HomeBrands />
      <div class="bg-#f7f7f7 px-4 py-5">
        <div class="grid grid-cols-4 gap-1 rounded-xl bg-white">
          <div v-for="item in data?.data?.brandList || []" :key="item.id" class="">
            <img class="aspect-2/1 w-full object-cover" :src="gradualImg(item.picture)" :alt="item.name">
          </div>
        </div>
      </div>
      <NuxtLink :external="isInsideIframe" :to="botttomImg.linkUrl" :target="botttomImg.openLink === 1 ? '_blank' : '_self'">
        <img mt-5 data-event-type="page-define" :data-event-id="botttomImg.id" :src="gradualImg(botttomImg.url as string)" :alt="botttomImg.name">
      </NuxtLink>
    </div>

    <SeoMoreContent :content="moreContent" />
    <app-tabbar />
  </div>
</template>

<style scoped lang="scss">
.main {
  .top {
    height: 52px;
    padding: 1.3rem 0.9375rem 0;
    :deep(.van-cell) {
      border-radius: 1.875rem;
    }
  }
  :deep(.van-dropdown-menu__bar) {
    box-shadow: initial;
    height: 30px;
  }
  :deep(.van-tabs__nav) {
    padding-right: 0;
    padding-left: 0;
  }
  :deep(.van-tabs__line) {
    display: none;
  }
  // 隐藏tab默认选中
  // :deep(.van-tab) {
  //   &:first-child {
  //   }
  // }
  /* 菜单键 */
  .fixedly-btn-right {
    flex: 1 0 auto;
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 calc(var(--van-padding-sm) / 2);
    height: 44px;
    background-color: #743a3a;
    color: var(--van-tab-text-color);
    line-height: var(--van-tab-line-height);
    z-index: 1;
    cursor: pointer;
    box-sizing: border-box;
  }
  .custom-swipe {
    min-height: 240px;
    :deep(.van-swipe-item) {
      background-color: #f7f7f7;
      img {
        width: 100%;
        height: 240px;
        padding: 20px 20px 40px 20px;
        border-radius: 20px;
        object-fit: cover;
        display: block;
        box-sizing: border-box;
      }
    }
    :deep(.van-swipe__indicator) {
      width: 0.9375rem;
      height: 0.125rem;
      border-radius: 0.625rem;
      background-color: #d3c0c0;
    }
    :deep(.van-swipe__indicator--active) {
      width: 1.875rem;
      height: 0.125rem;
      background-color: #743a3a;
    }
  }
  .Goods {
    background-color: #f7f7f7;
    padding: 15px;
    margin-top: 0.9375rem;
    .title {
      .title-left {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        align-items: center;
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 10px;
        }
      }
      .title-right {
        display: flex;
        align-items: center;
        color: #743a3a;
        font-size: 12px;
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .highlight {
        position: relative;
        color: #743a3a;
      }

      .highlight::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: #743a3a;
        border-radius: 2px;
        bottom: 0px;
        left: 0px;
      }
    }
    .title-level2 {
      display: flex;
      justify-content: space-between;
      margin-left: 0.625rem;
      margin-top: 0.625rem;
      min-height: 45px;
      .title-left {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        align-items: center;
        padding: 10px 5px 2px 5px;
        border: 1px solid #743a3a;
        border-radius: 6px 6px 0px 0px;
        height: 35px;
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .title-right {
        display: flex;
        align-items: center;
        color: #743a3a;
        font-size: 12px;
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .highbackground {
        background-color: #743a3a;
        border-radius: 6px 6px 0px 0px;
        color: #fff;
        height: 45px;
        padding: 5px 10px;
      }
    }
    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      div {
        img {
          width: 100%;
          border-radius: 6px 6px 6px 6px;
          object-fit: cover;
        }
        div {
          padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
          font-size: 14px;
        }
        .content-describe {
          color: #919191;
          font-size: 11px;
        }
      }
    }
    .content-info {
      color: #555;
      column-count: 2;
      column-gap: 20px;
      .item {
        margin-bottom: 20px;
        text-align: left;
        overflow: hidden;
        display: inline-block;
        img {
          width: 100%;
          height: auto;
          display: block;
          border-radius: 6px 6px 6px 6px;
        }
        div {
          padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
          font-size: 14px;
        }
        .content-describe {
          color: #919191;
          font-size: 11px;
        }
      }
    }
    .banner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      .banner-item {
        font-size: 14px;
        background-color: #f2e9ea;
        border-radius: 0.375rem;
        color: #743a3a;
        text-align: center;
        padding: 6px 0;
      }
    }
    .hightbanner {
      background-color: #743a3a;
      border-radius: 0.375rem;
      color: #fff;
      text-align: center;
      padding: 6px 0;
      font-size: 14px;
    }
  }
}
</style>
