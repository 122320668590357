import type { ImageOptions } from '@nuxt/image'

const DefaultFormat = ['webp']

export default (src: string, modifiers?: ImageOptions['modifiers'], options?: ImageOptions) => {
  if (!src) {
    return '/images/default/detailt-product.jpeg'
  }

  const img = useImage()
  const format = options?.format || DefaultFormat

  return img(src, {
    ...modifiers,
    format,
  }, options)
}
